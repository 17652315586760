@mixin font-face($style-name, $file, $category:"") {
    $filepath: "../static/fonts/" + $file;
    @font-face {
        font-family: "#{$style-name}";
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'), url($filepath + ".woff") format('woff'), url($filepath + ".ttf")  format('truetype'), url($filepath + ".svg#" + $style-name + "") format('svg');
        font-display: block;
    }
    %#{$style-name} {
        font: {
            @if $category != "" {
                family: "#{$style-name}", #{$category};
            }
            @else {
                family: "#{$style-name}";
                weight: normal;
            }
        }
    }
}
@include font-face('Kanit-Bold', 'Kanit-Bold', 'serif');
@include font-face('Kanit-Medium', 'Kanit-Medium', 'serif');
@include font-face('Kanit-Regular', 'Kanit-Regular', 'serif');
@include font-face('Kanit-Light', 'Kanit-Light', 'serif');

@include font-face('SFProDisplay-Bold', 'SFProDisplay-Bold', 'serif');
@include font-face('SFProDisplay-Regular', 'SFProDisplay-Regular', 'serif');
@include font-face('SFProDisplay-Medium', 'SFProDisplay-Medium', 'serif');
@include font-face('SFProDisplay-Semibold', 'SFProDisplay-Semibold', 'serif');
