@import "mixins.scss";
@import "fonts.scss";

* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  position: relative;
  display: block;
  padding: 0px;
  margin: 0px;
  min-width: 320px;
  font-family: "Kanit-Regular", "tahoma";
  font-weight: 400;
  color: #000;
  font-size: 16px;
  background: #f7f7f7;
  overflow-x: hidden;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
  }
}
button {
  font-family: "Kanit-Regular", "tahoma";
}
a,
a:hover,
a:active,
a:focus {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
ul,
li {
  padding: 0px;
  margin: 0px;
  text-decoration: none;
  list-style-type: none;
}
.disable,
.disabled {
  filter: grayscale(100%) !important;
  cursor: default;
  pointer-events: none;
}
.default {
  cursor: default;
  pointer-events: none;
}

.hide-tablet {
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
.show-tablet {
  display: none;
  @media only screen and (max-width: 1024px) {
    display: unset;
  }
}
.hide {
  display: none;
}

.ant-select-item-option-content {
  font-size: 0.9vw;
  font-family: "SFProDisplay-Regular";
  font-weight: normal;
  line-height: 2;
  @media only screen and (max-width: 1024px) {
    font-size: 2.4vw;
  }
  @media only screen and (max-width: 450px) {
    font-size: 3.25vw;
  }
}
.ant-select-dropdown {
  border-radius: 0.521vw !important;
  border: solid 0.5px #d3d3d3;
  box-shadow: none;
  @media only screen and (max-width: 1024px) {
    border-radius: 1vw !important;
  }
  @media only screen and (max-width: 450px) {
    border-radius: 1.5vw !important;
  }
}
.ant-select-item {
  padding: 0 1vw !important;
  @media only screen and (max-width: 1024px) {
    padding: 0 1.5vw !important;
  }
  @media only screen and (max-width: 450px) {
    padding: 0 2.5vw !important;
  }
  background-color: transparent !important;
}
.ant-select-item-option-content {
  border-bottom: solid 0.5px #ebebeb;
  padding: 0.5vw 0 !important;
  @media only screen and (max-width: 1024px) {
    padding: 1vw 0 !important;
  }
  @media only screen and (max-width: 450px) {
    padding: 2vw 0 !important;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
  color: #4774c2;
}
.ant-select-item-option-state {
  display: flex;
  align-items: center;
}

.ant-picker-dropdown {
  font-family: "SFProDisplay-Regular";
  font-weight: normal;
}

.ant-picker-header-view {
  color: #4774c2;
}

.ant-picker-header-view button:hover {
  color: #4774c2;
}

.ant-picker-header > button {
  color: #4774c2;
}

.ant-picker-header > button:hover {
  color: #4774c2;
}

.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #4774c2;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #4774c2;
}

.ant-picker-panel-container {
  border-radius: 0.521vw !important;
  border: solid 0.5px #d3d3d3;
  box-shadow: none;
  @media only screen and (max-width: 1024px) {
    border-radius: 1vw !important;
  }
  @media only screen and (max-width: 450px) {
    border-radius: 1.5vw !important;
  }
}

// .ant-picker-cell-in-view.ant-picker-cell-in-range .ant-picker-cell-inner {
//   color: #fff;
// }

// .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
//   background: #4774c2;
// }

// .ant-picker-cell-range-hover-start::before{
//   background: #4774c2;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before{
//   background: #4774c2;
// }

// .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before{
//   background: #4774c2;
// }

.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 100%;
}

.ant-picker-date-panel {
  padding: 0 12px;
}
.ant-picker-header {
  padding: 0;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-panel .ant-picker-footer {
  border-top: none;
  padding: 0 12px;
}
.ant-picker-footer-extra {
  padding: 0;
  border-top: 1px solid #f0f0f0;
}

[data-reach-combobox-popover] {
  border: none !important;
  border-radius: 0.521vw;
  @media only screen and (max-width: 1024px) {
    border-radius: 1vw;
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
}

.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  text-shadow: unset;
}

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: #4774c2;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #4774c2;
}

.ant-checkbox-checked::after {
  border: 1px solid #4774c2;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #4774c2;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #4774c2;
  border-top: 0;
  border-left: 0;
}

.ant-checkbox-inner {
  border: 1px solid #434343;
}

.ant-message-notice-content {
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.ant-message-custom-content.ant-message-success {
  > span {
    color: #169d5b;
  }
}

.ant-message-custom-content.ant-message-error {
  > span {
    color: #fd3b3b;
  }
}

.ant-message-custom-content {
  img {
    width: 1.5vw;
    margin: 0 0.8vw 0 0;
    display: inline-block;
    vertical-align: middle;
  }
  > span {
    font-family: "SFProDisplay-Medium";
    font-size: 0.9vw;
    vertical-align: middle;
  }
  @media only screen and (max-width: 1024px) {
    img {
      width: 3.5vw;
      margin: 0 1.5vw 0 0;
    }
    > span {
      font-size: 2.5vw;
    }
  }
  @media only screen and (max-width: 450px) {
    img {
      width: 4.5vw;
      margin: 0 3vw 0 0;
    }
    > span {
      font-size: 3.5vw;
    }
  }
}

.line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ant-image-preview-img {
  display: inline-block;
}
